import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Start from "./components/start/Start";
import Page from "./util/page/Page";
import Home from "./components/home/Home";
import About from "./components/about/About";
import Code from "./components/code/Code";
import Question from "./components/quest/Question";
import Post from "./components/post/Post";

function App() {
  const user = localStorage.profile || null;

  return (
    <>
      <Router basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route exac path="/" element={<Page components={<Home />} />}></Route>
          <Route
            exac
            path="/about"
            element={<Page components={<About />} />}
          ></Route>
          <Route
            exac
            path="/post"
            element={<Page components={<Post />} />}
          ></Route>
          <Route
            exac
            path="/question"
            element={<Page components={<Question />} />}
          ></Route>
          <Route
            exac
            path="/code"
            element={<Page components={<Code />} />}
          ></Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
