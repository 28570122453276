import React, { useState } from "react";
import "./navbar.css";
import { BiMenu } from "react-icons/bi";
import { IoCloseSharp } from "react-icons/io5";
import generateAvatar from "../../util/avatart/Avatargenerator";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import PostAddIcon from "@mui/icons-material/PostAdd";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import CodeIcon from "@mui/icons-material/Code";
import SettingsIcon from "@mui/icons-material/Settings";
import logo from "../../image/logo.svg";
import { Backdrop, Button } from "@mui/material";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [value, setValue] = React.useState(0);
  const [setting, setSetting] = useState(false);
  const [togleMenu, setToglemenu] = useState(false);
  const user = JSON.parse(localStorage.getItem("profile"));
  return (
    <div className="navbar">
      <div className="toglemenu">
        {togleMenu ? (
          <IoCloseSharp onClick={() => setToglemenu(false)} id="toglemenubtn" />
        ) : (
          <BiMenu onClick={() => setToglemenu(true)} id="toglemenubtn" />
        )}
        <div className="mobilemenu" style={{ display: togleMenu && "grid" }}>
          <Box
            sx={{ width: "100%", zIndex: "999", display: "grid" }}
            className="navbar-mb"
          >
            <Button
              sx={{
                my: 2,
                color: "white",
                display: "flex",
              }}
              type="submit"
              startIcon={<HomeIcon />}
            >
              <Link to="/">Home</Link>
            </Button>
            <Button
              sx={{
                my: 2,
                color: "white",
                display: "flex",
              }}
              startIcon={<PostAddIcon />}
            >
              Post
            </Button>
            <Button
              sx={{
                my: 2,
                color: "white",
                display: "flex",
              }}
              startIcon={<HelpCenterIcon />}
            >
              Question
            </Button>
            <Button
              sx={{
                my: 2,
                color: "white",
                display: "flex",
              }}
              startIcon={<CodeIcon />}
            >
              Compiler
            </Button>
            <Button
              sx={{
                my: 2,
                color: "white",
                display: "flex",
              }}
              startIcon={<InfoIcon />}
            >
              About
            </Button>
          </Box>
        </div>
      </div>
      <div className="logo">
        <h3>
          <img src={logo} alt="/" />
        </h3>
      </div>
      <div className="component">
        <Box sx={{ flexGrow: 1, display: "flex", gap: "1rem" }}>
          <Link to="/">
            <Button
              sx={{
                my: 2,
                color: "black",
                display: "flex",
              }}
              startIcon={<HomeIcon />}
            >
              <label className="component-text">Home</label>
            </Button>
          </Link>
          <Link to="/post">
            <Button
              sx={{
                my: 2,
                color: "black",
                display: "flex",
              }}
              startIcon={<PostAddIcon />}
            >
              <label className="component-text">Post</label>
            </Button>
          </Link>
          <Link to="/question">
            <Button
              sx={{
                my: 2,
                color: "black",
                display: "flex",
              }}
              startIcon={<HelpCenterIcon />}
            >
              <label className="component-text">Question</label>
            </Button>
          </Link>
          <Link to="/code">
            <Button
              sx={{
                my: 2,
                color: "black",
                display: "flex",
              }}
              startIcon={<CodeIcon />}
            >
              <label className="component-text">Compiler</label>
            </Button>
          </Link>
          <Link to="/about">
            <Button
              sx={{
                my: 2,
                color: "black",
                display: "flex",
              }}
              startIcon={<InfoIcon />}
            >
              <label className="component-text">About</label>
            </Button>
          </Link>
        </Box>
      </div>
      <section>
        <Backdrop
          sx={{ color: "fff" }}
          className="backdrop"
          open={setting || togleMenu}
          onClick={() => (setting ? setSetting(false) : setToglemenu(false))}
        ></Backdrop>
      </section>
      <div className="user">
        <div className="usershow" onClick={() => setSetting(true)}>
          {generateAvatar("Papunidze")}
          <span className="username">{"Papunidze"}</span>
        </div>
        <div className="settings" style={{ display: setting && "grid" }}>
          <Box
            sx={{ width: "100%", zIndex: "999", display: "grid" }}
            className="navbar"
          >
            <BottomNavigation
              showLabels
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              sx={{ display: "grid" }}
            >
              <BottomNavigationAction
                label="Profile"
                icon={<AccountCircleIcon />}
                sx={{ color: "black", fontWeight: "600" }}
              />
              <BottomNavigationAction
                label="Setting"
                icon={<SettingsIcon />}
                sx={{ color: "black", fontWeight: "600" }}
              />
              <BottomNavigationAction
                label="Logout"
                icon={<LogoutIcon />}
                sx={{ color: "black", fontWeight: "600" }}
              />
            </BottomNavigation>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
