import "./page.css";
import Navbar from "../navbar/Navbar";
import SpeedDialTooltipOpen from "../speeddial/Dial";
import Upload from "../upload/Upload";
export default function Page(props) {
  return (
    <div className="page">
      <div className="overwiev">
        <Navbar />
        <div className="page_generator">{props.components}</div>
      </div>
      <section className="speeddial">{SpeedDialTooltipOpen()}</section>
      <div className="page-system"></div>
      <ul className="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  );
}
